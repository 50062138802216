<template lang="pug">
    div(name='uon_widget_5372-45996-4R07mha20R', id='uon_widget_5372-45996-4R07mha20R', class='uon-widget')
</template>

<script>
    export default {
        name: "form-component",
        data() {
            return {
            };
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/general.scss";

</style>