<template lang="pug">
    li.procedure__item
        p.procedure__item-text {{text}}
        img(:src="require(`../assets/${img}`)")
</template>

<script>
    export default {
        name: "procedure-item",
        props: {
            index: {
                type: Number,
                required: true,
            },
            img: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
            };
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/general.scss";

    .procedure__item {
        width: 220px;
        height: 220px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #6FA5C3;
        border-radius: 15px;
        padding: 15px;
        text-align: center;
        margin-bottom: 20px;
        background-color: $whiteColor;

        &:not(:last-child):after {
            content: '';
            right: -15%;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #6FA5C3;
            border-radius: 2px;
        }
    }

    .procedure__item-text {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    @media (max-width: 1200px) {

        .procedure__item {
            width: 130px;
            height: 130px;

            img {
                width: 30px;
                margin-bottom: 15px;
            }
        }

        .procedure__item-text {
            font-size: 14px;
        }
    }

</style>