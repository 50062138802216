<template lang="pug">
    div
        h1.visually-hidden Онлайн турагенство Lucky Sunny и Travel Baunty. Подбор туров, отелей.
        div.fullscreen-bg
            div.overlay
                div.container.container--header
                    p.header__logo Ваш семейный турагент
                    h2.header__appeal.header__appeal--first-part  Посмотри на мир.
                    h2.header__appeal  Он куда удивительнее cнов.

            video(loop autoplay muted playsinline).fullscreen-bg__video
                source(src="./assets/firstVideo.mp4", type="video/mp4").firstVideo--desktop
                source(src="./assets/mobileFirstVideo.mp4", type="video/mp4").firstVideo--mobile

        button.header__burger-menu(@click="show = !show")
        nav.header__nav(:class="{'header__nav--opacity': !show}").animate__animated
            button.header__btn-close(@click="show = !show")
            ul.header__nav-list
                li.header__nav-item
                    a(href="#about").header__nav-link(@click="show = !show") Как выбрать тур?
                li.header__nav-item
                    button(@click="popup = !popup").header__nav-link.header__nav-link--request Оставить заявку
                li.header__nav-item
                    a(href="#procedure").header__nav-link(@click="show = !show") Порядок действий
                li.header__nav-item
                    a(href="#reviews").header__nav-link(@click="show = !show") Отзывы
                li.header__nav-item
                    a(href="#contacts").header__nav-link(@click="show = !show") Контакты

        main
            section.about
                div.container.about__container
                    div.about__info-container
                        h2.h2.about__h2.animate__animated.animate__fadeInUp.animate__delay-1s#about Выбери тур для себя
                        p.about__content.animate__animated.animate__fadeInUp.animate__delay-1s Хотите личного семейного турагента, который всегда на связи, знает ваши предпочтения и желания?
                        p.about__content.animate__animated.animate__fadeInUp.animate__delay-1s Уже знает ваши нюансы и не нужно писать 250 сообщений, чтобы тебя поняли - тогда "Добро пожаловать!" в семью.
                        p.about__content.animate__animated.animate__fadeInUp.animate__delay-1s А еще для моих всегда самые шикарные варианты и накопительные бонусы.
                        h3.about__h3.animate__animated.animate__fadeInUp.animate__delay-2s Ну и немного о себе:
                        ul.advantages__list.animate__animated.animate__fadeInUp.animate__delay-2s
                            li.advantages__item лично проинспектировала более 30 отелей;
                            li.advantages__item знаю, где лучше отдыхать с детьми- аллергиками;
                            li.advantages__item лучшие тусовочные отели  в моем списке;
                            li.advantages__item никогда не отправлю мам с колясками и пожилых в отели, где ступенчатые спуски к морю.
                    ul.about__variants-container
                        recreation-item(
                            v-for="recItem in recList",
                            :heading ="recItem.heading",
                            :text ="recItem.text"
                            :video ="recItem.video"
                            :animated ="recItem.animated"
                        )

        div.request__wrapper
            div.fullscreen-bg
                div.request__overlay

                video(loop="" muted="" autoplay="" playsinline).fullscreen-bg__video
                    source(src="./assets/secondVideo.mp4", type="video/mp4")
            div.request
                div.request__content-wrapper
                    p.request__text.animate__animated#request-step Сделай шаг к своему путешевствию
                    button(@click="popup = !popup").request__btn Оставить заявку

        div.popup__container(v-show="popup")
            div.popup
                form(v-if="!send", name="popup").popup__form
                    button.popup__btn-close(@click="popup = !popup")
                    h2.popup__heading Заявка на подбор тура
                    div(name='uon_widget_5372-45996-4R07mha20R', id='uon_widget_5372-45996-4R07mha20R', class='uon-widget')

        section.procedure
            div.container.procedure__container
                h2.h2.procedure__h2#procedure Порядок действий
                ul.procedure__list
                    procedure-item(
                        v-for="procedure in procedures",
                        :index ="procedure.index",
                        :img ="procedure.img",
                        :text ="procedure.text"
                    )

        section.reviews-contacts__container
            section.reviews
                div.container.reviews__container
                    h2.h2.reviews__h2#reviews Отзывы
                    ul.reviews__list
                        review-item(
                            v-for="review in reviews",
                            :name ="review.name",
                            :content ="review.content",
                        )

            footer.contacts
                div.container.contacts__container
                    h2.h2.contacts__h2#contacts Контакты
                    ul.contacts__tels-wrapper
                        li
                            a(href="tel:+79208942628").contacts__tels-link.animate__animated +7 920 894 26 28
                        li
                            a(href="mailto:lucky.sunny40@yandex.ru").contacts__tels-link.animate__animated lucky.sunny40@yandex.ru
                        li
                            a(href="https://wa.me/79208942628").contacts__tels-link.animate__animated whatsapp
                        li
                            a(href="https://t.me/lucky_sunny").contacts__tels-link.animate__animated telegram
                    div.contacts__form-wrapper
                        p.contacts__form-description Оставьте ваши контакты и мы свяжемся  с вами в ближайшее время:
                        button(@click="popup = !popup").request__btn.request__btn--contacts Оставить контакты
                    div.contacts__socials-wrapper
                        p.contacts__links-description Мы в соцсетях:
                        //a(href="https://instagram.com/lucky_sunny40?utm_medium=copy_link").contacts__link.contacts__link--inst.animate__animated
                        a(href="https://vk.com/fotosamohina").contacts__link.contacts__link--vk.animate__animated
                        //a(href="").contacts__link.contacts__link--fb.animate__animated
                        //a(href="https://instagram.com/travelbaunty?utm_medium=copy_link").contacts__link.contacts__link--inst.animate__animated
                    div.contacts__copyrights
                        p © Н. Самохина, {{currentYear}}
</template>

<script>

    import recreationItem from "./components/recreationItem.vue";
    import procedureItem from "./components/procedureItem";
    import reviewItem from "./components/reviewItem";
    import FormComponent from "./components/formComponent";


export default {
    created () {
        window.addEventListener('scroll', this.handleScroll);
        !function(e,o){window.uon=o;o.g_config={widget_id:'5372-45996-4R07mha20R',widget_w:'360px',widget_h:'600px',client_id:45996}; o.config=function(e){ this.g_config.user=e}}(document,window.uon||{});
        let newScript = document.createElement('script');
        newScript.setAttribute('src', 'https://uon.u-on.ru/widgets/widget_ssl.js');
        document.head.appendChild(newScript);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        sendMail(){
            if(this.name && this.tel && this.email && this.tel.length > 12) {
                fetch('mail.php', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: this.name,
                        phone: this.tel,
                        email: this.email
                    })
                }).then((res) => {
                    if (res.status === 200) {
                        this.send = true;
                    }
                });
            }
        },
        handleScroll () {
            console.log(window.scrollY);
            let requestStep = document.getElementById('request-step');

            /*варианты отдыха*/

            if(window.innerWidth > 920) {
                if(window.scrollY >= 650) {
                    this.recList[0].animated = true;
                }

                if(window.scrollY >= 1200) {
                    this.recList[1].animated = true;
                }

                if(window.scrollY >= 1750) {
                    this.recList[2].animated = true;
                }

                if(window.scrollY >= 2300) {
                    this.recList[3].animated = true;
                }

                if(window.scrollY >= 2850) {
                    this.recList[4].animated = true;
                }

                if(window.scrollY >= 3400) {
                    this.recList[5].animated = true;
                    window.removeEventListener('scroll', this.handleScroll);
                }
            }

            if(window.innerWidth <= 1200){

                if(window.scrollY >= 900) {
                    requestStep.style.opacity = "1";
                    requestStep.classList.add('animate__fadeIn');
                }

                if(window.scrollY > 2400) {
                    let telLinkList = document.querySelectorAll('.contacts__tels-link');
                    for(let i=0; i<telLinkList.length; i++) {
                        telLinkList[i].style.opacity = "1";
                        telLinkList[i].classList.add('animate__rotateInDownLeft');
                    }
                }

                if(window.scrollY > 2500) {
                    let socialsLinkList = document.querySelectorAll('.contacts__link');
                    for(let i=0; i<socialsLinkList.length; i++) {
                        socialsLinkList[i].style.opacity = "1";
                        socialsLinkList[i].classList.add('animate__rotateInDownRight');
                    }
                }
            }

            if(window.innerWidth > 1200) {

                if(window.scrollY > 2000) {
                    requestStep.style.opacity = "1";
                    requestStep.classList.add('animate__fadeIn');
                }
            }

        }
    },

    data (){
        return {
            name: '',
            tel: '',
            email: '',
            currentYear: (new Date()).getFullYear(),
            popup: false,
            show: false,
            procedures: [
                {
                    index: 1,
                    img: "icon1.svg",
                    text: "Подбираем тур",
                },
                {
                    index: 2,
                    img: "icon2.svg",
                    text: "Заключаем договор",
                },
                {
                    index: 3,
                    img: "icon3.svg",
                    text: "Вносим предоплату",
                },
                {
                    index: 4,
                    img: "icon4.svg",
                    text: "Бронируем тур",
                },
                {
                    index: 5,
                    img: "icon5.svg",
                    text: "Подготавливаем документ",
                },
            ],
            recList: [
                {
                    heading: "Пляжный отдых",
                    text: "Это жизненная необходимость. Даже любители активного отдыха нет нет да и положат пятую точку на мягкий лежак около бирюзового моря. А если у вас уже есть детки- то вы уже представляете насколько полезен соленый воздух и витамин D. Безветренные отели Египта, Доминикана без водорослей, отели Турции с безглютеновым питанием - все это в моем арсенале.",
                    video: require('./assets/recVideo1.mp4'),
                    animated: false,
                },
                {
                    heading: "Горнолыжный отдых",
                    text: "Для тех, кто выбирает более активное времяпрепровождение, покоряя снежные склоны великолепных горнолыжных курортов. Десятки живописных мест для зимних приключений, от Сочи до Аспена (США), — лучшее, что могут найти на сегодняшний день поклонники скоростных спусков. Знаю с чего начать и что предложить уже \"бывалым\".",
                    video: require('./assets/recVideo2.mp4'),
                    animated: false,
                },
                {
                    heading: "Круизы",
                    text: "Давайте я развею миф, что \"Круизы для богачей!\"  Уже тысячи туристов на себе проверили, что круизное путешествие- это сказка, которую можно и нужно себе позволить. В круизах всегда отменное питание, крутая развлекательная программа, и возможность побывать в разных городах или разных странах. Цена начинается от 40 000 на чел. Напиши и проверь.",
                    video: require('./assets/recVideo3.mp4'),
                    animated: false,
                },
                {
                    heading: "По России",
                    text: "В последнее время Россия расцвела в плане туристского отдыха. Конечно побывать в Европе и Азии и не побывать на Байкале- это непростительная ошибка. Не погулять на Куршской косе, не помедитировать на склоне гор Алтая, не сделать невероятной красоты снимки на озере Эльтон. А подводные Ординские пещеры в Пермском крае  - тоже не был? Тогда мне есть, что тебе рассказать.",
                    video: require('./assets/recVideo4.mp4'),
                    animated: false,
                },
                {
                    heading: "Экскурсионный",
                    text: "Многие совмещают с пляжным, но есть такие виды, на которые стоить слетать отдельно. Потому, что как говорится за двумя зайцами ...... Конечно Европа- это то самое направление, где необходимо запастись самой удобной обувью и наслаждаться большим изобилием исторической красоты.  А если еще совместить это с гастрономическим туром. Твои вкусовые сосочки будет тебе благодарны.",
                    video: require('./assets/recVideo5.mp4'),
                    animated: false,
                },
                {
                    heading: "Диснелейнд",
                    text: "Почему отдельная группа- да потому, что мечта моего детства. Уолт Дисней- просто гений. Он сделал это ради своих дочек. Сделал мечту для всех. Пять Диснейлендов – пять самых знаменитых в мире парков развлечений, где царят персонажи знаменитых мультфильмов. Там родители вместе со своими детьми наслаждаются фантастическими фейерверками, катаются на лучших водных аттракционах и познают истинную сущность волшебства.",
                    video: require('./assets/recVideo6.mp4'),
                    animated: false,
                }
            ],
            reviews: [
                {
                    name: 'Ирина П.',
                    content: 'Сначала было страшно, что договор подписывать онлайн и я не увижу турагента, не посижу в офисе, не потрачу кучу времени. Но все прошло отлично. Тур забронирован. Документы все на почте. Ждем, не дождемся.',
                },
                {
                    name: 'Станислав Ж.',
                    content: 'Доволен как слон! Шри-Ланка покорилась мне только с Натальей. Все объяснила, заполнила за меня все документы. Работать очень легко. Всегда можно обратиться за советом.',
                },
                {
                    name: 'Вячеслав М.',
                    content: 'С Натальей летим уже в 4 раз. Уже стала нашим семейным турагентом, знает все наши пожелания и сама уже предлагает варианты, когда есть что-то интересное. Летим в Турцию в отель Port Nature по смешной цене. Наталья – ты супер.',
                },
            ]
        }
    },
  name: 'App',
  components: {
      FormComponent,
      recreationItem,
      procedureItem,
      reviewItem
  },

}

</script>

<style lang="scss">
    @import "./assets/scss/general.scss";

    .animate__animated.animate__fadeInUp{
        --animate-duration: 1s;
    }

    .animate__animated.animate__fadeIn {
        --animate-duration: 2s;
    }

    .animate__animated.animate__rotateInDownRight, .animate__animated.animate__rotateInDownLeft, .animate__animated.animate__zoomIn {
        --animate-duration: 2s;
    }

    .fullscreen-bg {
        overflow: hidden;
        z-index: -100;
        position: relative;
        height: 100%;
        width: 100%;
        padding-top:45%;
    }

    .fullscreen-bg__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .overlay {
        background: rgb(255,255,255);
        background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 26%, rgba(255,255,255,1) 100%);
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }

    .header__video {
        position: fixed;
    }

    .container--header {
        padding: 70px 100px;

        &:after {
            content: '';
            position: absolute;
            width: 800px;
            height: 30px;
            background-color: #00406b;
            left: 50%;
            bottom: -2px;
            margin-left: -400px;
        }
    }

    .header__nav {
        position: absolute;
        top: 50px;
        right: 60px;
        background-color: rgba(255, 255, 255, 0.28);
    }

    .header__nav-link {
        font-weight: 500;
        font-size: 20px;
        transition: color 0.3s, background 0.3s;
        color: $blackColor;
        border-bottom: 2px solid transparent;

        &:hover {
            color: #072D90;
            border-bottom: 2px solid #072D90;
        }
    }

    .header__nav-item:not(:last-child) {
        margin-right: 20px;
    }

    .header__nav-link--request {
        border: none;
        background-color: transparent;
        font-family: "Montserrat", sans-serif;
        border-bottom: 2px solid transparent;
    }

    .header__logo {
        font-size: 22px;
        font-family: "Cormorant", sans-serif;
        font-weight: 700;
        margin-bottom: 150px;
    }

    .header__nav-list {
        display: flex;
        padding: 20px 50px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .header__appeal {
        font-family: "Cormorant", sans-serif;
        font-weight: 700;
        font-size: 60px;
        color: $blackColor;
    }

    .header__appeal--first-part {
        margin-bottom: 50px;
    }

    .firstVideo--mobile {
        display: none;
    }

    /*about*/

    .about {
        background: url("./assets/shells.png");
        background-size: auto;
    }

    .about__container {
        padding: 0 60px 70px;
    }

    .about__info-container {
        max-width: 800px;
        padding: 0 35px 55px;
        margin: 0 auto 45px;
        box-shadow: 0 60px 50px 2px rgba(12, 49, 51, 0.2);
        color: $whiteColor;
        background-color: #00406b;
        background-size: cover;
    }

    .about__content {
        margin-bottom: 30px;
    }

    .about__h2 {
        text-align: center;
        color: $whiteColor;
        border-bottom: 1px solid $whiteColor;
        width: 380px;
        margin: 0 auto 30px;
    }

    .about__h3 {
        font-family: "Cormorant", sans-serif;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px solid $whiteColor;
        width: 380px;
        margin: 0 auto 20px;
        font-size: 25px;
    }

    .advantages__item {
        position: relative;
        margin-left: 20px;
        line-height: 40px;

        &:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: $whiteColor;
            border-radius: 20px;
            left: -20px;
            top: 16px;
        }
    }

    /*variants*/

    .about__variants-container {
        padding: 25px;
        margin: 0 auto;
    }

    /*request*/

    .request__wrapper {
        position: relative;
    }

    .request__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .request__content-wrapper {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 50px;
    }

    .request {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #f1f5f7 0%, rgba(211,211,211,0.4) 20%, rgba(107,156,242,0.4) 84%, #7eb0e3 100%);
    }

    .request__text {
        font-family: "Cormorant", sans-serif;
        font-weight: 700;
        font-size: 55px;
        text-align: center;
        color: $blackColor;
        margin-bottom: 100px;
        opacity: 0;
    }

    .request__btn {
        height: 40px;
        width: 340px;
        padding: 10px 0;
        text-align: center;
        background: #F9EA9F;
        border-radius: 15px;
        border: none;
        font-weight: 500;
        font-size: 20px;
        margin: 0 auto;
    }

    /*popup*/

    .popup__container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(28, 33, 29, 0.92);
        z-index: 100;
    }

    .popup {
        position: relative;
        width: 540px;
        height: 480px;
        padding: 20px;
        background: #eaf8fd url("./assets/wave11.png") no-repeat right;
        background-size: cover;
    }

    .popup__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .popup__btn-close {
        position: absolute;
        width: 40px;
        height: 40px;
        right: -60px;
        top: -60px;
        border: none;
        background-color: transparent;

        &:before {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            background-color: $whiteColor;
            transform: rotate(-45deg);
            right: 4px;
        }

        &:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            background-color: $whiteColor;
            transform: rotate(45deg);
            right: 4px;
        }
    }

    .popup__input {
        font-size: 20px;
        padding: 10px 15px;
        margin-bottom: 20px;
        color: $blackColor;
        border: 1px solid #6EAAAA;

        &::placeholder {
            color: #ABABAB;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .popup__heading {
        font-weight: 600;
        font-size: 34px;
        font-family: "Cormorant", sans-serif;
        margin-bottom: 30px;
        text-align: center;
    }

    .popup__btn {
        height: 40px;
        width: 340px;
        padding: 10px 0;
        text-align: center;
        background: #F9EA9F;
        border-radius: 15px;
        border: none;
        font-weight: 500;
        font-size: 20px;
        margin: 0 auto 40px;
    }

    .popup__accept-text {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: center;
        color: $whiteColor;
        text-shadow: $blackColor 1px 3px 3px;
    }

    .popup__agreement {
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        color: $whiteColor;
        text-shadow: $blackColor 1px 3px 3px;
    }

    .popup__success--text {
        max-width: 310px;
        text-align: left;
        padding-left: 45px;
        font-size: 25px;
        margin-bottom: 20px;
    }

    .popup__success--text--footer {
        color: $whiteColor;
        font-size: 20px;
        margin-bottom: 30px;
    }

    /*rules*/

    .procedure {
        background: url("./assets/wave.png") repeat-x 0 102%, url("./assets/line.svg") repeat-x 0 10%, url("./assets/line.svg") repeat-x 0 50%, url("./assets/line.svg") repeat-x 0 80%, linear-gradient(180deg, #f1f5f7 0%, #a4d0ef 100%);
        background-size: auto;
    }

    .procedure__container {
        position: relative;
        padding: 80px 70px 230px;
    }

    .procedure__h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    .procedure__list {
        display: grid;
        grid-template-columns: repeat(5, auto);
        justify-content: center;
        column-gap: 50px;
    }

    /*reviews*/

    .reviews-contacts__container {
        background: linear-gradient(180deg, #6eaaaa 0%, rgba(16,35,53,1) 100%);
    }

    .reviews__container {
        padding: 0 110px 100px;
        background: url("./assets/leaf.png") no-repeat right;
        background-size: 30%;
    }

    .reviews__h2 {
        text-align: center;
        color: $whiteColor;
        margin-bottom: 60px;
    }

    .reviews__list {
        display: flex;
        justify-content: left;
    }

    /*contacts*/

    .contacts__container {
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: repeat(3, 1fr);
        padding: 0 110px;
        column-gap: 10px;
        background: url("./assets/leaf2.png") no-repeat right, url("./assets/leaf.png") no-repeat 20% 65%;
        background-size: 30%, 10%;
    }

    .contacts__h2 {
        grid-area: 1/1/2/-1;
        justify-self: center;
        text-align: center;
        color: $whiteColor;
        margin-bottom: 60px;
    }

    .contacts__img {
        width: 300px;
        margin-right: 40px;
        opacity: 0;
    }

    .request__btn--contacts {
        display: block;
    }

    /*footer*/

    .footer {
    }

    .contacts__tels-wrapper {
        grid-area: 2/1/3/2;
    }

    .contacts__form-wrapper {
        grid-area: 2/2/3/3;
    }

    .contacts__socials-wrapper {
        grid-area: 2/3/3/4;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
    }

    .contacts__tels-wrapper {

        a {
            color: $whiteColor;
            font-size: 20px;
        }
    }

    .contacts__form-description {
        color: $whiteColor;
        margin-bottom: 40px;
        font-size: 20px;
    }

    .contacts__input{
        font-size: 20px;
        padding: 10px 15px;
        margin-bottom: 20px;
        color: $whiteColor;

        &::placeholder {
            color: $whiteColor;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .contacts__links-description {
        color: $whiteColor;
        margin-bottom: 40px;
        font-size: 20px;
    }

    .contacts__tels-link {
        display: inline-block;
        margin-bottom: 30px;
        border-bottom: 2px solid transparent;

        &:hover {
            color: #BBF4FC;
            border-bottom: 2px solid #BBF4FC;
        }
    }

    .contacts__form-wrapper {

        input {
            height: 65px;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            border: 1px solid $whiteColor;
            border-radius: 15px;
        }
    }

    .contacts__link {
        width: 40px;
        height: 40px;
        margin-bottom: 45px;
    }

    .contacts__link--inst {
        background-image: url("./assets/instagram.svg");
    }

    .contacts__link--vk {
        background-image: url("./assets/vk.svg");
    }

    .contacts__link--fb {
        background-image: url("./assets/fb.svg");
    }

    .contacts__copyrights {
        grid-area: 3/1/4/-1;
        display: flex;
        justify-content: space-between;
        color: $whiteColor;
        margin-bottom: 20px;
        padding-top: 30px;

        a {
            color: $whiteColor;
        }
    }


    @media (min-width: 1201px) {

        .header__btn-close, .header__burger-menu{
            display: none;
        }

    }

    @media (max-width: 920px) {

        .about__variants-container {
            padding: 10px;
            overflow: hidden;
            overflow-x: auto;
            display: flex;
        }

        .about__variants-block {
            min-width: 300px;
            grid-template-rows: 25px auto;
            padding: 15px;
            grid-template-columns: 30px 1fr;
            gap: 15px 10px;

            &:before {
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                background-size: contain;
            }
        }

        .about__variants-block:not(:last-child) {
            margin-bottom: 0;
            margin-right: 10px;
        }

        .about__variants-heading {
            grid-area: 1/2/2/3;
            font-size: 16px;
        }

        .about__content {
            grid-area: 3/1/4/-1;
        }

        .about__video {
            grid-area: 2/1/3/-1;
            justify-self: center;
            width: 180px;
        }

        .about__h2 {
            margin-bottom: 15px;
            width: 250px;
        }

        .about__h3 {
            width: 250px;
        }

        .about__content {
            line-height: 20px;
            margin-bottom: 20px;
        }

        .about__content, .about__h3, .advantages__item {
            font-size: 14px;
        }

    }

    @media (max-width: 1350px) {

        .header__nav-link {
            font-size: 16px;
        }
    }


    @media (max-width: 1200px) {

        .header__nav-list {
            position: relative;
            top: 75px;
            flex-direction: column;
            padding: 15px 30px;
            background: #eaf8fd url("./assets/wave11.png") no-repeat right;
            background-size: cover;
        }

        .header__nav {
            position: fixed;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(28, 33, 29, 0.92);
            z-index: 10;
        }

        .header__nav-item {
            margin-bottom: 10px;
        }

        .header__nav--opacity {
            display: none;
        }

        .header__nav-link {
            font-size: 16px;
            text-shadow: $whiteColor 1px 3px 3px;
        }

        .header__burger-menu {
            background: transparent url("./assets/burger.svg") no-repeat;
            background-size: contain;
            border: none;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .header__btn-close {
            position: absolute;
            display: block;
            border: none;
            background-color: transparent;
            width: 50px;
            height: 50px;
            right: 25px;
            top: 22px;

            &:before {
                content: '';
                position: absolute;
                width: 40px;
                height: 2px;
                background-color: $whiteColor;
                transform: rotate(45deg);
                right: -1px;
                z-index: 100;
            }

            &:after {
                content: '';
                position: absolute;
                width: 40px;
                height: 2px;
                background-color: $whiteColor;
                transform: rotate(-45deg);
                right: 0;
                z-index: 100;
            }
        }

        .container--header {
            &:after {
                display: none;
            }
        }

        .header__logo {
            font-size: 14px;
            margin-bottom: 35px;
        }

        .header__appeal {
            font-size: 20px;
        }

        .header__appeal--first-part {
            margin-bottom: 10px;
        }

        /*about*/

        .about__container {
            padding: 0;
        }

        .about__info-container {
            padding: 30px 10px 20px;
        }

        /*request*/

        .request {
            padding: 20px;
        }

        .request__text {
            font-size: 18px;
            margin-bottom: 20px;
            opacity: 0;
        }

        .request__btn {
            width: 200px;
            font-size: 18px;
        }

        /*procedure*/

        .procedure__container {
            padding: 25px 10px 85px;
        }

        .procedure__list {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .procedure__item {
            margin-bottom: 55px;
        }

        .procedure__h2 {
            margin-bottom: 20px;
        }

        .procedure__item:not(:last-child) {

            &:after {
                bottom: -35px;
                right: auto;
            }
        }


        /*advantages*/

        .advantages__item {
            line-height: 20px;
            margin-bottom: 20px;

             &:before {
                 top: 7px;
             }
        }

        /*reviews*/

        .reviews__container {
            padding: 5px 10px 20px;
        }

        .reviews__h2 {
            margin-bottom: 30px;
        }

        .reviews__list {
            overflow: hidden;
            overflow-x: auto;
            padding-bottom: 20px;
        }

        .reviews__p--text {
            font-size: 14px;
        }

        .reviews__p--name {
            font-weight: 500;
            margin-bottom: 15px;
            font-size: 16px;
        }

        /*contacts*/

        .contacts__container {
            grid-template-columns: 1fr;
            padding: 5px 10px;
        }

        .contacts__h2 {
            grid-area: 1/1/2/-1;
            margin-bottom: 30px;
        }

        .contacts__tels-wrapper {
            grid-area: 2/1/3/-1;
            margin-bottom: 20px;

            a {
                display: inline-block;
                font-size: 18px;
            }
        }

        .contacts__form-wrapper {
            grid-area: 3/1/4/-1;
            margin-bottom: 30px;
        }

        .contacts__socials-wrapper {
            grid-area: 4/1/5/-1;
            width: 50%;
            margin: 0 auto 30px;
        }

        .contacts__tels-link {
            opacity: 0;
        }

        .contacts__link {
            justify-self: center;
            opacity: 0;
        }

        .contacts__links-description {
            grid-area: 1/1/2/-1;
            justify-self: center;
        }


        .contacts__form-description {
            font-size: 16px;
            text-align: center;
        }

        .contacts__img {
            grid-area: 5/1/6/-1;
            margin: 0 auto;
        }

        .contacts__copyrights {
            grid-area: 6/1/7/-1;
            padding-top: 55px;

            p, a {
                font-size: 12px;
            }

            a {
                text-align: right;
            }
        }

    }

    @media (max-width: 1200px) and (min-width: 700px) {

        /*header*/

        .container--header {
            padding: 90px 50px;
        }

        .header__logo {
            font-size: 18px;
        }

        .header__burger-menu {
            top: 70px;
            right: 90px;
            background-size: contain;
        }

        .header__appeal {
            font-size: 40px;
        }

        .header__appeal--first-part {
            margin-bottom: 50px;
        }

        .request__text {
            font-size: 40px;
            margin-bottom: 50px;
        }

        .reviews {
            margin-bottom: 30px;
        }

        /*contacts*/

        .contacts__container {
            padding: 10px 70px;
        }

        .contacts__input {
            max-width: 500px;
            margin: 0 auto 20px;
        }

        .contacts__tels-wrapper {
            display: flex;
            justify-content: space-between;
        }

    }

    @media (max-width: 699px) {

        .container--header {
            padding: 20px;
        }

        .firstVideo--desktop {
            display: none;
        }

        .firstVideo--mobile {
            display: block;
        }

        .request {
            top: 0;
            padding: 5px;
        }

        .request__content-wrapper {
            padding: 15px;
        }

        .request__btn {
            font-size: 16px;
            height: 35px;
            line-height: 10px;
        }

        .procedure__item-text {
            margin-bottom: 5px;
        }

        .procedure__item {
            padding: 25px 15px 0;
        }

        .popup {
            width: 350px;
            padding: 15px 10px;
            background-position: 0 100%;
            background-size: contain;
        }

        .popup__heading {
            font-size: 25px;
        }

        .popup__btn {
            width: 290px;
        }

        .popup__btn-close {
            right: 5px;
        }
    }

</style>
