<template lang="pug">
    li.reviews__item
        p.reviews__p.reviews__p--name {{name}}
        p.reviews__p.reviews__p--text {{content}}

</template>

<script>
    export default {
        name: "review-item",
        props: {
            name: {
                type: String,
                required: true,
            },
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/general.scss";

    .reviews__item {
        width: 250px;
        min-width: 250px;
        height: 350px;
        padding: 15px;
        border-radius: 15px;
        color: $blackColor;
        background: $whiteColor url("../assets/222.png") no-repeat 12% 0;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .reviews__p {
        font-size: 18px;
        text-align: center;
    }

    .reviews__p--name {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .reviews__p--text {
        font-weight: 400;
    }

</style>