<template lang="pug">
    li.about__variants-block
        h4.about__variants-heading {{heading}}
        p.about__content(:class="{'animate__animated': animated,'animate__backInLeft': animated, 'not-animated': !animated}") {{text}}
        video(loop="" muted="" autoplay="" playsinline).about__video(:class="{'animate__animated': animated,'animate__backInRight': animated, 'not-animated': !animated}")
            source(:src="video", type="video/mp4")
</template>

<script>
    export default {
        name: "recreation-item",
        props: {
            heading: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            video: {
                type: String,
                required: true,
            },
            animated: {
                type: Boolean,
                required: true,
            },

        },
        data() {
            return {
            };
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/general.scss";


    .about__variants-block {
        max-width: 1000px;
        display: grid;
        grid-template-columns: 60px auto auto;
        gap: 20px 40px;
        grid-template-rows: 70px auto;
        background-color: $whiteColor;
        padding: 30px;
        margin: 0 auto;
        box-shadow: 0 60px 50px 2px rgb(12 49 51 / 20%);

        &:before {
            content: '';
            background-image: url("../assets/flag.svg");
            grid-area: 1/1/2/2;
            width: 30px;
            height: 40px;
        }

        &:not(:last-child){
            margin-bottom: 30px;
        }
    }

    .about__variants-heading {
        grid-area: 1/2/2/3;
        font-family: "Cormorant", sans-serif;
        font-weight: 700;
        font-size: 30px;
        color: rgba(21, 16, 17, 0.4);
    }

    .about__content {
        grid-area: 2/1/3/3;
        font-size: 16px;
        text-indent: 30px;
        line-height: 35px;
    }

    .about__video {
        grid-area: 1/3/3/4;
        width: 260px;
    }

    @media (min-width: 920px) {
        .not-animated {
            visibility: hidden;
        }
    }



</style>